<template>
  <div>
    <tab-headers class="mb-5">
      Manage Practitioners
      <template #text> Manage (Approve, Decline) </template>
    </tab-headers>
    <a-modal
      title="Reason for not approving Account"
      :dialog-style="{ top: '20px' }"
      :visible="suspendModal"
      ok-text="Disapproved"
      cancel-text="Cancel"
      @ok="actionOnUser('disapproved')"
      @cancel="suspendModal = false"
    >
      <div>
        <p>Give details about why this account is being disapproved</p>
        <a-textarea v-model="reasonForDecline" required> </a-textarea>
      </div>
    </a-modal>
    <div class="grid md:grid-cols-2 grid-cols-1 md:gap-10 gap-2">
      <div>
        <p class="mb-0 ml-2 text-sm">Search</p>
        <a-input
          v-model="searchKeyword"
          placeholder="Search..."
          @change="debounceSearch"
        ></a-input>
      </div>
      <div>
        <p class="mb-0 ml-2 text-sm">Filter Practitioner</p>
        <a-select
          v-model="searchByParticipation"
          style="width: 100%"
          :filter-option="filterOptionP"
          @change="debounceSearch"
          placeholder="Practitioner Type"
        >
          <a-select-option v-for="p in practitioner" :key="p.id" :value="p.id">
            {{ p.title }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div
      class="
        mt-5
        bg-white
        rounded
        shadow
        grid
        max-w-full
        overflow-x-scroll
        py-4
        p-2
      "
    >
      <a-table
        :pagination="pagination"
        :row-key="record => record._id"
        :loading="gettingUser"
        :columns="headers"
        :data-source="lawyers"
        @change="getAllUsers"
      >
        <span slot="action" slot-scope="record">
          <a-button @click="showDrawer(record)">View </a-button>
        </span>
        <span slot="status" slot-scope="record">
          <div
            :class="
              `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
            "
          >
            {{ record.status }}
          </div>
        </span>
        <span slot="verified" slot-scope="record">
          <div
            :class="
              `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
            "
          >
            {{ record.isVerified ? 'Verified' : 'Not Verified' }}
          </div>
        </span>
      </a-table>
    </div>
    <a-drawer
      width="450"
      placement="right"
      closable
      :visible="visible"
      @close="onClose"
    >
      <a-avatar :size="105" :src="lawyer.avatar" />
      <h3 class="text-2xl capitalize mb-5">{{ lawyer.lastName }}'s Profile</h3>
      <div class="grid md:grid-cols-2 grid-cols-1">
        <div class="hidden">
          <p v-if="lawyer.isSubscribed" class="font-semibold">
            This user has a active Subscription
          </p>
          <p v-else class="font-semibold">
            This user don't have a active Subscription
          </p>
        </div>
        <div class="hidden">
          <p v-if="lawyer.isListed" class="font-semibold">
            This user has a active Listing plan
          </p>
          <p v-else class="font-semibold">
            This user don't have a active Listing plan of
            {{ lawyer.listingType }}
          </p>
        </div>
        <div>
          <p class="mb-0">First Name</p>
          <p class="font-semibold">{{ lawyer.firstName }}</p>
        </div>
        <div>
          <p class="mb-0">Last Name</p>
          <p class="font-semibold">{{ lawyer.lastName }}</p>
        </div>
        <div>
          <p class="mb-0">Other Name</p>
          <p class="font-semibold">{{ lawyer.otherName }}</p>
        </div>
        <div class="col-span-2">
          <p class="mb-0">Email</p>
          <p class="font-semibold">{{ lawyer.email }}</p>
        </div>
        <div>
          <p class="mb-0">Gender</p>
          <p class="font-semibold capitalize">{{ lawyer.gender }}</p>
        </div>
        <div>
          <p class="mb-0">Date of Birth</p>
          <p class="font-semibold capitalize">{{ lawyer.dateOfBirth }}</p>
        </div>
        <div>
          <p class="mb-0">Phone</p>
          <p class="font-semibold">
            {{ lawyer.phone }}
          </p>
        </div>
        <div>
          <p class="mb-0">Registered On</p>
          <p class="font-semibold">
            {{ new Date(lawyer.createdAt).toDateString() }}
          </p>
        </div>
        <div>
          <p class="mb-0">Last Profile Update</p>
          <p class="font-semibold">
            {{ new Date(lawyer.updatedAt).toDateString() }}
          </p>
        </div>
        <div class="col-span-2">
          <p class="mb-0">Areas of Practices</p>
          <div class="flex flex-wrap">
            <span
              v-for="practice in lawyer.areasOfPractice"
              :key="practice._id"
              class="text-sm px-2 py-1 mx-1 my-1 capitalize"
              >{{ practice.practice.title }}</span
            >
          </div>
        </div>

        <div>
          <p class="mb-0">State</p>
          <p class="font-semibold">
            {{ lawyer.state ? lawyer.state.title : '' }}
          </p>
        </div>
        <div>
          <p class="mb-0">Town</p>
          <p class="font-semibold">
            {{ lawyer.town ? lawyer.town.title : '' }}
          </p>
        </div>
        <div>
          <p class="mb-0">Law Firm</p>
          <p class="font-semibold">{{ lawyer.lawFirm }}</p>
        </div>

        <div>
          <p class="mb-0">Year called to Bar</p>
          <p class="font-semibold">{{ lawyer.callToBarYear }}</p>
        </div>
        <div>
          <p class="mb-0">Business Address</p>
          <p class="font-semibold">{{ lawyer.businessAddress }}</p>
        </div>
        <div v-if="lawyer.proofOfPayment">
          <p class="mb-0">Download Proof of Payment</p>
          <template v-if="checkLinkType(lawyer.proofOfPayment) === 'image'">
            <a download target="_blank" :href="lawyer.proofOfPayment"
              >Download</a
            >
          </template>
          <template v-else>
            <a
              download
              target="_blank"
              @click="
                downloadFile(lawyer.proofOfPayment, lawyer, 'Payment Of Proof')
              "
              >Download</a
            >
          </template>
        </div>
        <div v-if="lawyer.callToBarCertificate">
          <p class="mb-0">Download Call to Bar Certificate</p>
          <template
            v-if="checkLinkType(lawyer.callToBarCertificate) === 'image'"
          >
            <a download target="_blank" :href="lawyer.callToBarCertificate"
              >Download</a
            >
          </template>
          <template v-else>
            <a
              download
              target="_blank"
              @click="
                downloadFile(
                  lawyer.callToBarCertificate,
                  lawyer,
                  'Call to Bar Certificate'
                )
              "
              >Download</a
            >
          </template>
        </div>
        <div
          v-if="lawyer.otherCertificates && lawyer.otherCertificates.length > 0"
          class="mt-3"
        >
          <p class="mb-0">Download Other Professional Certificate</p>
          <template
            v-if="checkLinkType(lawyer.otherCertificates[0]) === 'image'"
          >
            <a download target="_blank" :href="lawyer.otherCertificates[0]"
              >Download</a
            >
          </template>
          <template v-else>
            <a
              download
              target="_blank"
              @click="
                downloadFile(
                  lawyer.otherCertificates[0],
                  lawyer,
                  'Other Certificate'
                )
              "
              >Download</a
            >
          </template>
        </div>
      </div>
      <div class="pt-10"></div>
      <div class="flex items-center justify-end">
        <a-button
          :style="{ marginRight: '8px' }"
          :disabled="actionOnUserLoading"
          @click="onClose"
        >
          Cancel
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          type="error"
          :disabled="lawyer.status === 'suspended' || actionOnUserLoading"
          :loading="actionOnUserLoading"
          @click="suspendModal = true"
        >
          Reject/Disapproved
        </a-button>
        <a-button
          type="primary"
          :disabled="lawyer.status === 'active' || actionOnUserLoading"
          @click="actionOnUser('active')"
        >
          Approve
        </a-button>
      </div>

      <!-- <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >

      </div> -->
    </a-drawer>
  </div>
</template>

<script>
import _ from 'lodash'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import usersApi from '../../api/users'

const headers = [
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName'
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email'
  },
  {
    title: 'Status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'LawyerChecker',
  components: { TabHeaders },
  layout: 'admindashboard',
  data() {
    return {
      searchByParticipation: 'lawyer',
      practitioner: [
        {
          id: 'lawyer',
          title: 'Lawyer'
        },
        {
          id: 'lawfirm',
          title: 'Law Firm'
        }
      ],
      suspendModal: false,
      reasonForDecline: '',
      headers,
      gettingUser: false,
      lawyers: [],
      searchKeyword: '',
      lawyer: {},
      visible: false,
      actionOnUserLoading: false,
      pagination: {
        limit: 10,
        page: 10,
        defaultPageSize: 10,
        offset: 0
      }
    }
  },
  created() {
    this.getAllUsers(this.pagination)
    this.debounceSearch = _.debounce(this.searchClient, 500)
  },
  methods: {
    async searchClient() {
      if (this.searchKeyword === '') {
        this.getAllUsers(this.pagination)
        return
      }
      this.gettingUser = true
      const req = await usersApi(this.axios).getAllUserAdmin(
        { role: this.searchByParticipation },
        {
          keyword: this.searchKeyword,
          populate: ['state', 'town', 'areasOfPractice.practice'],
          limit: 0,
          offset: 0,
        }
      )
      this.lawyers = req.data.data
      this.gettingUser = false
    },
    // eslint-disable-next-line no-unused-vars
    async getAllUsers(pagination) {
      this.gettingUser = true
      const req = await usersApi(this.axios).getAllUserAdmin(
        { role: this.searchByParticipation },
        {
          populate: ['state', 'town', 'areasOfPractice.practice'],
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        }
      )
      this.lawyers = req.data.data
      this.pagination = { ...this.pagination, ...req.data.meta }
      this.pagination.page =
        Math.ceil(this.pagination.total / this.pagination.limit) === Infinity
          ? 0
          : Math.ceil(this.pagination.total / this.pagination.limit)

      this.gettingUser = false
    },
    async actionOnUser(action) {
      this.actionOnUserLoading = true
      const req = await usersApi(this.axios).updateUserStatus({
        userId: this.lawyer._id,
        status: action,
        reason: this.reasonForDecline
      })
      this.suspendModal = false
      if (req.error) {
        this.$store.dispatch('notification/updateNotification', {
          type: 'error',
          message: req.message
        })
        this.reasonForDecline = ''
        this.actionOnUserLoading = false
        return
      }
      this.reasonForDecline = ''
      this.$notification.success({
        message: `Account ${action === 'suspended' ? 'declined' : 'Approved'}`,
        description: req.message
      })
      this.actionOnUserLoading = false
      this.getAllUsers()
      this.onClose()
    },
    showDrawer(lawyer) {
      this.lawyer = lawyer
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.lawyer = {}
    },
    checkLinkType(link) {
      const imagesFormat = [
        '.jpg',
        '.jpeg',
        '.jfif',
        '.pjpeg',
        '.pjp',
        '.png',
        '.svg'
      ]
      return imagesFormat.some(format => link.toString().includes(format))
        ? 'image'
        : 'file'
    },
    filterOptionP(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    },
    downloadFile(link, user, docType) {
      window.URL = window.URL || window.webkitURL

      const xhr = new XMLHttpRequest()
      const a = document.createElement('a')
      let file

      xhr.open('GET', link, true)
      xhr.responseType = 'blob'
      xhr.onload = function() {
        file = new Blob([xhr.response], { type: 'application/octet-stream' })
        a.href = window.URL.createObjectURL(file)
        a.classList.add('.hidden')
        a.download = `${docType}_${user.firstName}_${user.lastName}.pdf` // Set to whatever file name you want
        // Now just click the link you created
        // Note that you may have to append the a element to the body somewhere
        // for this to work in Firefox
        document.getElementsByTagName('body')[0].appendChild(a)
        a.click()
      }
      xhr.send()
    }
  }
}
</script>
